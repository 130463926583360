<template>
  <div class="b-card-content">
    <b-form v-if="chosenPool">
      <div class="form-pool-choice px-5 pt-3 pb-4">
        <div class="caption">
          Pool tokens
          <span v-b-tooltip.hover title="Number of pool tokens you will deposit in order to get LQF tokens" clickable>
                    <b-icon-question-circle></b-icon-question-circle>
                </span>
        </div>
        <b-input-group>
          <b-form-input
              placeholder="Enter amount"
              v-on:input="checkIfValid($event); fillForm($event)"
          ></b-form-input>
          <b-input-group-append>
            <b-input-group-text bordered>
              <div class="btn-gray p-1">
                <TokenPair
                    :tFrom=chosenPool.pair[0]
                    :tTo=chosenPool.pair[1]
                ></TokenPair>
              </div>
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </div>
      <b-form-row class="px-5">
        <b-col sm="12" md="6" class="mt-3">
          <TextBlock label="Pool tokens deposited"
                     tooltip="You can remove this tokens at any time. You will receive the same amount of tokens as you have deposited earlier"
                     :amount="mathRound(chosenPool.pool_tokens_deposited).toString()"
                     :amountTooltip="bNValue(chosenPool.pool_tokens_deposited)">
          </TextBlock>
        </b-col>

        <b-col sm="12" md="6" class="mt-3">
          <TextBlock label="Pool tokens available"
                     tooltip="You can deposit this tokens to receive more LQF tokens each week. You can remove this tokens at any time"
                     :amount="mathRound(chosenPool.pool_tokens_available).toString()"
                     :amountTooltip="bNValue(chosenPool.pool_tokens_available)"
                     :copy="bNValue(chosenPool.pool_tokens_available)">
          </TextBlock>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import {mathRound} from "@/helpers/utils";
import BigNumber from "bignumber.js";

export default {
  name: "DepositPool",
  components: {
    TokenPair: () => import("@/components/form/TokenPair"),
    TextBlock: () => import("@/components/form/TextBlock"),
  },
  props: {
    id: {type: String},
    chosenPool: {type: Object}
  },
  data: function () {
    return {
      form: {
        poolTokens: null,
        poolIncomeAfterDeposit: null
      },
      confirmData: {}
    }
  },
  watch: {
    'chosenPool': {
      handler: async function () {
        this.resetForm()
      },
      immediate: true
    },
  },
  methods: {
    mathRound(number) {
      return mathRound(number, 6)
    },
    bNValue(value) {
      return new BigNumber(value).valueOf()
    },
    fillConfirmData() {
      this.confirmData = {
        pool: {
          symbol: `${this.chosenPool.pair[0]}/${this.chosenPool.pair[1]} pool tokens`,
          amount: this.form.poolTokens
        },
        listData: []
      }
    },
    fillForm(value) {
      const bnValue = new BigNumber(value).valueOf()
      value = +value || 0
      this.form.poolTokens = bnValue
      const income = (this.chosenPool.lqf_rate / this.chosenPool.pool_tokens_deposited) *
          (this.chosenPool.pool_tokens_deposited + value)
      this.form.poolIncomeAfterDeposit = income > 0 && income < this.chosenPool.pool_tokens_deposited ? income : null
      this.fillConfirmData()
    },
    checkIfValid(value) {
      value = +value || 0
      if (value < 0 || value > this.chosenPool.pool_tokens_available) {
        this.$emit('valid', false);
      } else {
        this.$emit('valid', true);
      }
    },
    resetForm() {
      this.form = {
        poolTokens: null,
        poolIncomeAfterDeposit: null
      }
      this.confirmData = {}
    }
  }
}
</script>

<style scoped>

</style>
